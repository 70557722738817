import {toast} from 'react-toastify';
import api from './api';

function fetchUsers(params) {
  return api.get('/admin/users', {params}).then(res => res.data);
}
function fetchUser(id) {
  return api.get(`/admin/user/${id}`).then(res => res.data);
}
function fetchUserCompanyStatus(id) {
  return api.get(`/admin/companies?user=${id}`).then(res => res.data);
}
async function exportUsers() {
  await new Promise(resolve => setTimeout(resolve, 1000));
  const response = await api.get('/admin/users/export').then(res => res.data);
  toast.success(response?.message);
  return response;
}

export {fetchUsers, fetchUser, fetchUserCompanyStatus, exportUsers};
