import React, {useMemo, useState} from 'react';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import Select from 'react-select';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import moment from 'moment';
import {
  Modal,
  PrimaryButton,
  customStyles,
  DropdownIndicator,
} from '../../../components';
import {
  getCompanyTeamMembers,
  updateTeamMemberRole,
} from '../../../helpers/queryFns/fetchCompanyDetails';

export default function TransferOwnershipModal({open, setOpen}) {
  const {id} = useParams();
  const queryClient = useQueryClient();
  const [email, setEmail] = useState(null);

  const {data: teamMembersQuery, isLoading} = useQuery(
    ['team-members-query', id],
    () => getCompanyTeamMembers(id),
  );

  // filter users who've been added within the last 24hrs
  const currentTime = moment();
  const filteredMembers = useMemo(
    () =>
      !isLoading &&
      teamMembersQuery?.team.filter(member => {
        const memberCreatedAt = moment(member.createdAt);
        const duration = moment.duration(currentTime.diff(memberCreatedAt));
        const hoursDifference = duration.asHours();
        return hoursDifference > 24 && member.permissions.role !== 'Owner';
      }),
    [currentTime, isLoading, teamMembersQuery?.team],
  );

  const currentOwnerEmail = useMemo(
    () =>
      !isLoading &&
      teamMembersQuery?.team.find(member => member.permissions.role === 'Owner')
        ?.email,
    [isLoading, teamMembersQuery?.team],
  );

  const emailOptions =
    !isLoading &&
    filteredMembers &&
    filteredMembers
      ?.filter(
        member =>
          member?.permissions.role !== 'Owner' &&
          member?.email !== currentOwnerEmail,
      )
      .map(member => ({
        value: member?.email,
        label: member?.email,
      }));

  const {mutate: transferOwnership, isLoading: transferringOwnership} =
    useMutation(member => updateTeamMemberRole(member), {
      onSuccess: res => {
        toast.success(res.data.message);
        queryClient.invalidateQueries('team-members-query');
        setOpen(false);
        setEmail(null);
      },
      onError: error => {
        const errorMessage = error.response?.data?.error || 'An error occurred';
        toast.error(errorMessage);
      },
    });

  const handleTransferOwnership = e => {
    e.preventDefault();
    const payload = {
      email: email.value,
      role: 'Owner',
      company: id,
    };

    transferOwnership(payload);
  };

  return (
    <Modal
      show={open}
      modalTitle="Transfer admin ownership"
      onClose={setOpen}
      extraModalClasses="max-w-[528px] overflow-y-auto h-fit max-h-[95%] sm:max-h-full rounded-b-none sm:rounded-b-lg absolute bottom-0 sm:relative lookup-modal"
      modalPosition="justify-center items-center"
      noBottomPadding
      customHeadingSize
    >
      <section className="flex flex-col gap-4 mt-6">
        <p className="text-body font-medium text-start">
          By making this user the owner, you are giving them complete ownership
          of this company&apos;s application access
        </p>
      </section>

      <form onSubmit={handleTransferOwnership} className="mt-6">
        <label
          htmlFor="new_owner_email"
          className="flex flex-col items-start max-w-full text-sm label text-grey60 mb-2"
        >
          Email address of new owner
        </label>
        <Select
          defaultValue={emailOptions?.[0]?.value}
          value={email}
          onChange={setEmail}
          options={emailOptions}
          components={{DropdownIndicator}}
          classNamePrefix="react-select"
          styles={customStyles}
          menuPlacement="top"
          className="text-left"
          required
          maxMenuHeight={150}
          placeholder="Select email..."
        />

        <div className="-mx-6 flex items-center gap-4 p-6 pb-12 overflow-x-hidden">
          <PrimaryButton
            fontSize="text-sm"
            buttonText="Make owner"
            className="w-full"
            loadingState={transferringOwnership}
            disabled={transferringOwnership}
          />
        </div>
      </form>
    </Modal>
  );
}
