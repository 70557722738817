import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useQuery} from 'react-query';
import moment from 'moment';
import {back, search} from '../../assets/images/images';
import DashboardLayout from '../../components/DashboardLayout';
import LayoutContainer from '../../components/LayoutContainer';
import Pagination from '../../components/Pagination';
import TableLayout from '../../components/TableLayout';
import {fetchUser, fetchUserCompanyStatus} from '../../helpers/queryFns/users';
import Loader from '../../components/Loader';

function UsersProfile() {
  const {state} = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState('1');

  const {data, isFetching, isLoading} = useQuery(['user', state], () =>
    fetchUser(state),
  );
  const {data: userCompanyStatus} = useQuery(
    ['user-company-status', state],
    () => fetchUserCompanyStatus(state),
  );

  const pageClick = selected => {
    setCurrentPage(selected);
  };

  const stat = userCompanyStatus?.companies;

  let user = data?.user;

  user = {...data, stat};

  return (
    <DashboardLayout className="mt-4" headerText="Users/Profile">
      <LayoutContainer>
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 font-medium text-tiny text-brandBlue"
        >
          <img src={back} alt="" />
          Back to users
        </button>

        <div className="flex flex-wrap items-center w-auto gap-6 mt-5 sm:flex-nowrap">
          <div className="w-[78px] max-w-[78px] sm:max-w-[140px] sm:w-full flex items-center justify-center max-h-[78px] h-[78px] sm:max-h-[140px] sm:h-[140px] bg-grey80 text-[40px] sm:text-[72px] text-white rounded-full leading-none">
            {isLoading
              ? '-'
              : (user &&
                  ` ${
                    user?.user?.first_name?.substr(0, 1) || '-'.toUpperCase()
                  }${
                    user?.user?.last_name?.substr(0, 1) || '-'.toUpperCase()
                  }`) ||
                '-'}
          </div>
          <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit h-fit">
            <h3 className="px-3 py-1 rounded-[5px] bg-lightBlue text-brandBlue text-sm w-fit">
              Full name
            </h3>
            <h4 className="text-lg font-bold text-grey80">
              {isLoading
                ? '- -'
                : `${user?.user?.first_name || '-'} ${
                    user?.user?.last_name || '-'
                  }` || '- -'}
            </h4>
          </div>
          <div className="flex flex-col px-4 py-2 space-y-2 rounded-lg grow sm:grow-0 bg-lighterBlue w-fit h-fit">
            <h3 className="px-3 py-1 rounded-[5px] bg-lightBlue text-brandBlue text-sm w-fit">
              Email address
            </h3>
            <h4 className="text-lg font-bold text-grey80">
              {isLoading ? '-' : user?.user?.email}
            </h4>
          </div>
        </div>

        <div>
          <h4 className="mt-12 mb-4 font-medium text-grey60">Company list</h4>
          <div className="flex flex-wrap justify-between gap-4">
            <label htmlFor="search" className="relative label">
              <input
                name="search"
                placeholder="Search users list"
                className="input"
              />
              <img src={search} alt="" className="absolute top-4 right-4" />
            </label>
          </div>
        </div>

        <div className="mt-3">
          {isLoading ? (
            <Loader />
          ) : (
            <TableLayout>
              <thead className="text-xs font-semibold text-gray-400 uppercase bg-gray-50">
                <tr className="border-b border-tableGrey">
                  <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                    Company name
                  </th>
                  <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                    Status
                  </th>
                  <th className="p-3 font-medium text-left text-tiny text-grey60 whitespace-nowrap">
                    Date created
                  </th>
                </tr>
              </thead>

              <tbody
                className="text-sm divide-y divide-gray-100"
                style={{opacity: isFetching && 0.4}}
              >
                {user?.stat?.map((userData, i) => (
                  <tr className="border-b border-tableGrey" key={i}>
                    <td className="p-3 text-sm whitespace-nowrap">
                      {userData?.name ?? '-'}
                    </td>
                    <td
                      className={`p-3 text-sm text-left text-grey80 whitespace-nowrap ${
                        !userData?.suspended ? 'text-success' : 'text-danger'
                      } `}
                    >
                      {userData?.suspended ? 'Suspended' : 'Active'}
                    </td>
                    <td className="p-3 text-sm text-left text-grey80 whitespace-nowrap">
                      {moment(userData?.createdAt).format('Do MMM YYYY')}
                    </td>
                  </tr>
                ))}
              </tbody>
            </TableLayout>
          )}
        </div>

        <div className="flex flex-wrap items-baseline justify-center gap-4 mb-14 sm:justify-between">
          <Pagination
            total={(user && user?.companies?.totalPages) || 0}
            current={+currentPage}
            onPageChange={activePage => {
              pageClick(activePage);
            }}
          />
        </div>
      </LayoutContainer>
    </DashboardLayout>
  );
}

export default UsersProfile;
