import React, {useState, useEffect} from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {dropdownBlue} from '../../assets/images/images';
import {getCompanyServices} from '../../helpers/queryFns/fetchCompanyDetails';

export default function GhanaKycServices({onUpdate}) {
  const [accordion, setAccordion] = useState(false);

  const {id} = useParams();

  const [ghanaKycDl, setGhanaKycDl] = useState('');
  const [ghanaKycPassport, setGhanaKycPassport] = useState('');
  const [ghanaKycSsnit, setGhanaKycSsnit] = useState('');
  const [ghanaKycTin, setGhanaKycTin] = useState('');
  const [ghanaKycVoter, setGhanaKycVoter] = useState('');

  const ghanaKycServicesQuery = useQuery(['ghana-kyc-services-query', id], () =>
    getCompanyServices(id),
  );
  const url = ghanaKycServicesQuery?.data?.pricing;

  useEffect(() => {
    const getGhanaKycServicesQuery = () => {
      setGhanaKycDl((url?.ghana_kyc_dl / 100).toFixed(2));
      setGhanaKycPassport((url?.ghana_kyc_passport / 100).toFixed(2));
      setGhanaKycSsnit((url?.ghana_kyc_ssnit / 100).toFixed(2));
      setGhanaKycTin((url?.ghana_kyc_tin / 100).toFixed(2));
      setGhanaKycVoter((url?.ghana_kyc_voter / 100).toFixed(2));
    };

    getGhanaKycServicesQuery();
  }, [
    url?.ghana_kyc_dl,
    url?.ghana_kyc_passport,
    url?.ghana_kyc_ssnit,
    url?.ghana_kyc_tin,
    url?.ghana_kyc_voter,
  ]);

  return (
    <>
      {ghanaKycServicesQuery.isLoading ? (
        '...'
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-wrap justify-between gap-4 mt-8">
            <div
              onClick={() => setAccordion(!accordion)}
              className="bg-lightBlue py-3 pl-4 pr-3 flex justify-between rounded-lg max-w-full w-[440px] whitespace-nowrap cursor-pointer"
            >
              <h4 className="font-medium text-grey80">Ghana Kyc services</h4>
              <div className="flex items-center gap-4">
                <img
                  src={dropdownBlue}
                  alt=""
                  className={`${
                    accordion ? 'rotate-180 ease-in' : 'rotate-0 ease-out'
                  } duration-200 transition`}
                />
              </div>
            </div>
          </div>

          {accordion && (
            <div className="flex justify-between flex-wrap p-6 bg-lighterBlue max-w-[440px] mt-2 rounded-lg">
              <div className="space-y-4">
                <div>
                  <label
                    htmlFor="ghana_kyc_dl"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Ghana Kyc Dl
                    <input
                      id="ghana_kyc_dl"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ghanaKycDl}
                      onChange={e =>
                        onUpdate({
                          ghana_kyc_dl: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ghana_kyc_passport"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Ghana Kyc Passport
                    <input
                      id="ghana_kyc_passport"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ghanaKycPassport}
                      onChange={e =>
                        onUpdate({
                          ghana_kyc_passport: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ghana_kyc_ssnit"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Ghana Kyc Ssnit
                    <input
                      id="ghana_kyc_ssnit"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ghanaKycSsnit}
                      onChange={e =>
                        onUpdate({
                          ghana_kyc_ssnit: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ghana_kyc_tin"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Ghana Kyc Tin
                    <input
                      id="ghana_kyc_tin"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ghanaKycTin}
                      onChange={e =>
                        onUpdate({
                          ghana_kyc_tin: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>

                <div>
                  <label
                    htmlFor="ghana_kyc_voter"
                    className="flex flex-col items-start max-w-full w-[184px] mb-2 text-sm text-grey80"
                  >
                    Ghana Kyc Voter
                    <input
                      id="ghana_kyc_voter"
                      type="number"
                      className="max-w-full w-[184px] mt-1 bg-lightBlue outline-none border-0 pl-4 h-12"
                      defaultValue={ghanaKycVoter}
                      onChange={e =>
                        onUpdate({
                          ghana_kyc_voter: e.target.value * 100,
                        })
                      }
                    />
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}
